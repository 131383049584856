import Card from '../../components/Card';
import Header from '../../components/Header';
import { i18n } from '../../translate/i18next';
import MiniMenu from '../Gameform/MiniMenu';

import mockTeam from './__mocks_team.json';
function Team() {
  const team = mockTeam.team;

  return (
    <>
      <div className="content-team">
        <div className="container container-team mx-auto row">
          <Header title={i18n.t('team')} btnSpan={true} textBtn="Whitepaper" />
          <MiniMenu />
          <div className="col-md-12">
            <h1 className="text-center">{i18n.t('teams.apresentation')}</h1>
          </div>
          <div className="comando-team col-md-12 row ">
            <h1 className="text-center">{i18n.t('teams.label1')}</h1>
            {team.map(
              (i) =>
                i.comando && (
                  <div className="col-md-12 col-lg-4">
                    {' '}
                    <Card
                      img={i.img}
                      name={i.nome}
                      cargo={i.cargo}
                      imgNft={i.imgNft}
                      categoria={i.categoria}
                    />
                  </div>
                )
            )}
          </div>
          <div className="core-team col-md-12 row">
            <h1 className="text-white text-center ">
              {i18n.t('teams.label2')}
            </h1>
            {team.map((i) => (
              <>
                {i.core && (
                  <div className="col-md-12 col-lg-4">
                    {' '}
                    <Card
                      img={i.img}
                      name={i.nome}
                      cargo={i.cargo}
                      imgNft={i.imgNft}
                      categoria={i.categoria}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="col-md-12 row core-team ">
            <h1 className="text-center">{i18n.t('teams.label3')}</h1>
            {team.map((i) => (
              <>
                {i.secondary && (
                  <div className="col-lg-4 col-md-12">
                    <Card
                      img={i.img}
                      name={i.nome}
                      cargo={i.cargo}
                      imgNft={i.imgNft}
                      categoria={i.categoria}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
