/* eslint-disable jsx-a11y/anchor-is-valid */
function CardBorderLight(props) {
  return (
    <>
      <section>
        <div className="container-border-light m-4">
          <div className="background-img">
            <div className="box">
              <span />
              <span />
              <span />
              <span />

              <div className="content">
                <h2> {props.titleCard} </h2>
                <img src={props.img} alt="img-startps" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CardBorderLight;
