/* eslint-disable no-undef */
import Header from '../../components/Header';
import mocksStartups from './__mocks_startups.json';
import CardBorderLight from '../../components/CardBorderLight';
import { i18n } from '../../translate/i18next';
import Loader from '../../components/Loader';
import { useState } from 'react';

function Startups() {
  const startups = mocksStartups.startups;
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <>
      {!loading ? (
        <main className="content-startups">
          <div className="container container-startups row">
            <Header
              title={i18n.t('titles.startups')}
              imgSrc="/dist/img/icons/demo-startup.svg"
              btnSpan={true}
              textBtn={i18n.t('startups.btnDemo')}
            />

            <h1 className=" text-center font-atmospheric">
              {i18n.t('startups.subtitle')}
            </h1>
            <div className="d-flex justify-content-around  col-lg-12 col-md-12">
              <h3 className="apresentation-startups col-md-12 ext-white-50 text-center">
                {i18n.t('startups.apresentation')}
              </h3>
            </div>
            <div className="d-flex  d-lg-none col-12 mt-3">
              <button className="w-100  btn-span btn-demo">
                <p>{i18n.t('startups.btnDemoMobile')}</p>
              </button>
            </div>
            {startups.map((i) => (
              <>
                <div className="col-md-12 col-lg-4 wrapper-startup">
                  {<CardBorderLight titlecCard={i.name} img={i.img} />}
                </div>
              </>
            ))}
          </div>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Startups;
