import { messages as englishMessages } from './en';
import { messages as portugueseMessages } from './pt';

const messages = {
  ...englishMessages,
  ...portugueseMessages,
};

export { messages };
12;
