/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactElasticCarousel from 'react-elastic-carousel';
import { i18n } from '../../translate/i18next';

import 'react-toastify/dist/ReactToastify.css';
//import { useHistory } from 'react-router-dom';

const handleSubscribe = () => {
  window.open('https://gameform.com.br/game-jam/');
};
function CarouselPromo(props) {
  return (
    <>
      <ReactElasticCarousel
        className="carousel-promo"
        itemsToShow={1}
        showArrows={props.hiddenArrow}
        enableAutoPlay={props.autoPlay}
        autoPlaySpeed={props.speed}
        showEmptySlots={false}
        tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
        transitionMs={700}
      >
        {props.releases?.map((item) => (
          <>
            <div className="btn-carousel ">
              <div className="wrapper-title-promo font-atmospheric col-md-12 ">
                <h2>{item.titlePromo}</h2>
                <button
                  className="btn-small btn-span "
                  onClick={() => handleSubscribe()}
                >
                  {i18n.t('subscribe')}
                </button>
              </div>
              <span />
              <span />
              <div className="container-promo-hub   ">
                <div className="wrapper-release ">
                  <div className="text-release">{i18n.t('releases')}</div>
                </div>

                <video className="opacity-75" autoPlay="autoplay" controls>
                  <source src={item.videoSrc} type="video/mp4" />
                  <source src={item.videoSrc} type="video/ogg" />
                </video>
              </div>
            </div>
          </>
        ))}
      </ReactElasticCarousel>
    </>
  );
}

export default CarouselPromo;
