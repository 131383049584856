/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import { useState } from 'react';
// import { getAllTokens } from '../../services/gameversoService';

import { i18n } from '../../translate/i18next';

// import { Container } from './styles';
function BestRating({ games }) {
  /*

  useEffect(() => {
    async function getTokens() {
      const response = await getAllTokens();
      console.log(response);
      setcmc(response);
    }
    getTokens();
  }, []);
*/
  let arrGames = [];

  if (games) {
    arrGames.push(
      games.sort(function (a, b) {
        if (a.cmc_rank > b.cmc_rank) {
          return -1;
        }
      })
    );
  }

  return (
    <>
      <div>
        <div className="title-top-6 my-5">
          <p className="text-center fs-2 mt-5">{i18n.t('titleTop6')}</p>
        </div>

        <div className="col-md-12 row d-flex justify-content-between mx-auto">
          {arrGames[0]?.map((item, index) => (
            <>
              <div
                key={index}
                onClick={() => {
                  history.push('games/battlebots');
                }}
                className="btn-carousel col-lg-3 m-3 col-md-12 my-3 card-best-rating mx-auto"
                type="button"
              >
                <span />
                <span />

                <div className="info-card ">
                  <div className="wrapper-name-game">
                    {' '}
                    <div className="d-flex flex-column col-md-12">
                      <p className="text-center fs-3 font-atmospheric">
                        {index + 1} &#176;{' '}
                      </p>
                      <div className="wrapper-best-rating d-flex alig-items-center justify-content-center gap-3">
                        <img
                          className="icon-best-rating"
                          src={`/dist/img/icons/coins/${item.symbol.toLowerCase()}.svg`}
                          alt="icon-coin"
                        />
                        <p className="fs-2 fw-bolder text-center mt-3">
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="wrapper-icon-price">
                    {/* <img
                    src={`/dist/img/icons/coins/${item.symbol.toLowerCase()}.svg`}
                    alt="icon-coin"
                  />
                  <p> {item.symbol} </p>

                  <p style={{ color: '#7cc91f' }}>
                    {' '}
                    {`$${item.quote.USD.price.toString().substr(0, 5)}`}
                </p> */}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default BestRating;
