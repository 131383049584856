/* eslint-disable no-self-assign */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import BtnWallet from '../BtnWallet';
import BtnWhitepaper from '../BtnWhitepaper';
//import BtnSpan from '../BtnSpan';
import { useRef, useState, useEffect } from 'react';
import { useStateValue } from '../../contexts/StateContext';
import { useHistory } from 'react-router-dom';
import { i18n } from '../../translate/i18next';
function Header(props) {
  const history = useHistory();

  const [btnWallet, setBtnWallet] = useState(props.btnWallet);
  const [btnWhitepaper, setBtnWhitepaper] = useState(props.btnWhitepaper);
  const [btnSpan, setBtnSpan] = useState(props.btnSpan);
  const user = useStateValue();
  const [title] = useState(props.title);

  useEffect(() => {
    if (location.pathname !== '/') {
      if (location.pathname !== '/hub') {
        let headerElement = document.querySelector('.container-btn-wallet');
        headerElement.style.marginBottom = '100px';
      }
    }
  }, []);

  console.log(location.pathname);
  return (
    <>
      <div className="col-md-12 container-btn-wallet  d-flex justify-content-between align-items-center d-none d-lg-flex container">
        <h1 className={`${props.class} text-title font-atmospheric`}>
          {console.log(title)}
          {i18n.t(`titles.${title}`)}
        </h1>

        {btnSpan && (
          <BtnWhitepaper text={props.textBtn} imgSrc={props.imgSrc} />
        )}

        {btnWallet && <BtnWallet text="connect wallet" />}
      </div>
    </>
  );
}

export default Header;
