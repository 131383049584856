//import Home from './Pages/Home';
import Champ from './Pages/Champ';
import Games from './Pages/Games';
import Lives from './Pages/Lives/';
import Partners from './Pages/Partners';
import GamesNoticias from './Pages/Games';
import SideBar from './components/SideBar';
import Hub from './Pages/Hub';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import Team from './Pages/Team';
import Startups from './Pages/Startups';
import Nfts from './Pages/Nfts';
import Gameform from './Pages/Gameform';
import NewsLatest from './Pages/NewsLatest';
import Post from './components/Post';
import HelpUs from './Pages/Helpus';
//import Home from './Pages/Home';
import Market from './Pages/Market';
import GameInfo from './Pages/GameInfo';
import Gamejam from './Pages/Gamejam';

//glueLeftFromRight
export default function Routes() {
  return (
    <BrowserRouter>
      <SideBar />

      <Route
        render={({ location }) => {
          return (
            <PageTransition
              preset={'cubeToRight'}
              transitionKey={location.pathname}
            >
              <Switch location={location}>
                <Route path="/register" exact />
                <Route path="/hub/games/battlebots" exact>
                  <GamesNoticias />
                </Route>
                <Route path="/" exact>
                  <Hub />
                </Route>
                <Route path="/hub" exact>
                  <Hub />
                </Route>

                <Route path="/" exact>
                  <Hub />
                </Route>
                <Route path="/hub" exact>
                  <Hub />
                </Route>

                <Route path="/hub/games" exact>
                  <Games />
                </Route>
                <Route path="/hub/gameform/partners" exact>
                  <Partners />
                </Route>

                <Route path="/hub/champ" exact>
                  <Champ />
                </Route>
                <Route path="/hub/lives" exact>
                  <Lives />
                </Route>
                <Route path="/hub/gameform/team" exact>
                  <Team />
                </Route>

                <Route path="/hub/startups" exact>
                  <Startups />
                </Route>

                <Route path="/hub/gamejam" exact>
                  <Gamejam />
                </Route>

                <Route path="/hub/gameform" exact>
                  <Gameform />
                </Route>
                <Route path="/hub/nfts" exact>
                  <Nfts />
                </Route>

                <Route path="/hub/gameform/newslatest" exact>
                  <NewsLatest />
                </Route>

                <Route path="/hub/marketplace" exact>
                  <Market />
                </Route>

                <Route path="/hub/gameform/helpus" exact>
                  <HelpUs />
                </Route>

                <Route path="/hub/gameform/blog/post">
                  <Post />
                </Route>

                <Route path="/hub/games/gameinfo">
                  <GameInfo />
                </Route>
              </Switch>
            </PageTransition>
          );
        }}
      />
    </BrowserRouter>
  );
}
