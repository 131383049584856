/* eslint-disable no-self-assign */
/* eslint-disable import/no-unresolved */
import { useHistory } from 'react-router-dom';

import NewChamp from '../../Champ/NewChamp';
import Dropdown from '../../../components/Dropdown';
// import { Container } from './styles';

function SideBarRight(props) {
  const history = useHistory();

  //const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
  const leaveHub = (event) => {
    event.preventDefault();
    document.getElementById('content-sidebar').style.display = 'none';
    history.push('/');
    history.go();
  };

  return (
    <>
      <div className={` ${props.showSideBarRight}`}>
        <div className="container-sidebar-right">
          <div className="div-btn-sidebar-right d-none  d-sm-block ">
            <Dropdown hidden={'d-none d-lg-block'} />
          </div>
          <div className="div-btn-sidebar-right d-none d-lg-block" id="a">
            <span />
            <span />

            <button className="btn btn-incolor  text-white" onClick={leaveHub}>
              <img
                className="img-fluid"
                src="/dist/img/icons/arrow-back.svg"
                alt=""
              />
            </button>
          </div>
          <div className="div-btn-sidebar-right" id="a">
            <span />
            <span />

            <button
              className="btn btn-incolor "
              type="button"
              // data-toggle="modal"
              //  data-target="#new_champ"
            >
              <img
                className="img-fluid "
                src="/dist/img/icons/gift.svg"
                alt=""
              />
            </button>
          </div>
          <div className="div-btn-sidebar-right" id="a">
            <span />
            <span />

            <button
              className="btn btn-incolor "
              type="button"
              //  data-toggle="modal"
              // data-target="#new_champ"
            >
              <img
                className="img-fluid "
                src="/dist/img/icons/new_champ.svg"
                alt=""
              />
            </button>
          </div>
          <div className="div-btn-sidebar-right" id="a">
            <span />
            <span />

            <button
              className=" btn btn-incolor"
              type="button"
              //  data-toggle="modal"
              // data-target="#new_live"
            >
              <img
                className="img-fluid"
                src="/dist/img/icons/new_live.svg"
                alt=""
              />
            </button>
            <span />
          </div>
        </div>
        <NewChamp />
      </div>
    </>
  );
}

export default SideBarRight;
