const messages = {
  en: {
    translations: {
      titles: {
        hub: 'hub',
        games: 'Games',
        partnersadv: 'partners & advisors',
        partners: 'partners',
        team: 'team',
        startups: 'startups',
        posts: 'posts',
        blog: 'Latest',
        helpus: 'helpus',
        gamejam: 'gamejam',
      },
      menu: {
        sidebar: {
          home: 'home',
          games: 'games',
          live: 'live',
          gamejam: 'gamejam',
          market: 'market',
          startups: 'startups',
          gameverse: 'gameverse',
          nfts: 'nfts',
        },
      },

      miniMenu: {
        blog: 'blog',
        partners: 'partners',
        team: 'team',
        helpus: 'helpus',
        about: 'about',
      },
      titleCarousel: 'featured games',
      subscribe: 'subscribe',
      wallet: 'connect wallet',
      community: 'community ',
      footerText: 'a step further in the world of web3 games',
      release: 'releases',
      comingsoon: 'coming soon',
      titleRanking: 'largeest volume in 24h',
      titleTop6: 'Top 6 gameform',
      teams: {
        apresentation:
          'Meet the gameform crew who join forces to guide this ship.   ',
        label1: 'Captain',
        label2: 'Immediates',
        label3: 'reserve module',
      },
      startups: {
        title: 'gameform startups',
        subtitle: 'Ecosystem Gameform',
        apresentation: 'Your startup on the center of an inovative ecosystem.',
        btnDemo: 'schedule demo',

        btnDemoMobile: 'schedule',
      },
      gamejam: {
        title: 'Gamejam',
        subtitle: 'Gameform incentive Ecosystem',
        apresentation: 'Your startup on the center of an inovative ecosystem.',
        btnDemo: 'Subscribe',
      },
    },
  },
};

export { messages };
