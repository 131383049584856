/* eslint-disable jsx-a11y/media-has-caption */

//import Header from '../../components/Header';
import { i18n } from '../../translate/i18next';
// import { Container } from './styles';

function Nfts() {
  return (
    <>
      <div className="content-team">
        <div className="container-team d-flex align-items-center justify-content-center nfts">
          <h1 className="font-atmospheric fs-1 ">{i18n.t('comingsoon')}</h1>
        </div>
      </div>
    </>
  );
}

export default Nfts;
