/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from './Table.js';

import Pagination from './components/Pagination';

const PaginateGames = () => {
  const [coinsData, setCoinsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function getTokens() {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false'
      );

      setCoinsData(response.data);

      if (response) {
        setCoinsData(response.data);
      }
    }

    getTokens();
  }, []);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = coinsData.slice(firstPostIndex, lastPostIndex);

  return (
    <div className="app">
      <h1 className="text-center ">Table Games / Comming Soon /</h1>

      <form className="col-md-12 row mx-auto d-flex justify-content-between">
        <div className="form-group col-md-6 input-table">
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Searh game"
          />
          <small id="emailHelp" className="form-text text-muted" />
        </div>

        <div className="form-group col-md-2 input-table">
          <select className="form-control" id="exampleFormControlSelect1">
            <option selected disabled>
              Categories
            </option>
            <option>Collectibles NFT</option>
            <option>Last news IGO</option>
            <option>Play to Earn</option>
            <option>Metaverse</option>
          </select>
        </div>

        <div className="form-group col-md-2 input-table">
          <select className="form-control" id="exampleFormControlSelect1">
            <option selected disabled>
              Best
            </option>
            <option>ROI</option>
            <option>Volume 24h</option>
            <option>Percent Price 24h</option>
            <option>Popular</option>
          </select>
        </div>
      </form>

      <Table tokens={currentPosts} />
      <Pagination
        totalPosts={coinsData.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default PaginateGames;
