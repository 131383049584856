const messages = {
  pt: {
    translations: {
      titles: {
        games: 'Jogos',
        partnersadv: 'parceiros & advisors',
        partners: 'parceiros',
        posts: 'posts',
        team: 'time',
        startups: 'startups',
        blog: 'Ultimos posts',
      },
      menu: {
        sidebar: {
          home: 'inicio',
          games: 'jogos',
          live: 'ao vivo',
          market: 'mercado',
          gamejam: 'gamejam',
          startups: 'startups',
          gameverse: 'gameverso',
          nfts: 'nfts',
          helpus: 'Ajude nos',
        },
      },

      miniMenu: {
        blog: 'blog',
        partners: 'parceiros',
        team: 'time',
        helpus: 'ajuda',
        about: 'sobre',
      },
      subscribe: 'Inscrever-se',
      titleCarousel: 'jogos em destaque',
      titleRanking: 'maior volume em 24h',
      titleTop6: 'Top 6 gameform',
      wallet: 'conectar carteira',
      community: 'comunidade',
      footerText: 'um passo adiante no univero games web3',
      btnDemo: 'agendar demosntração',
      releases: 'lancamentos',
      comingsoon: 'Em breve',
      startups: {
        title: 'gameform startups',
        subtitle: 'Ecosistema gameform',
        apresentation:
          'Sua startup no centro de um ecossistema inovador Conte com a experiência de mercado da GameForm e parceiros para impulsionar seus negócios. ',
        btnDemo: 'agendar demonstração',
      },
      teams: {
        apresentation:
          'Conheça os tripulantes da gameform que unem suas forças para guiar esta nave.',
        label1: 'Capitao',
        label2: 'imediatos',
        label3: 'modulo reserva',
      },
    },
  },
};

export { messages };
