/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useEffect, useState } from 'react';

function Card(props) {
  let { to } = props;

  const [classCard, setClassCard] = useState('');
  useEffect(() => {
    if (props.partners) {
      setClassCard('bg-black img-card-partners');
    } else {
      setClassCard('img-team');
    }
  }, [props.partners]);

  return (
    <>
      <div className="card-container d-flex mx-auto">
        <div className="nft" onClick={() => window.open(to)} onKeyPress={''}>
          <div className="main">
            <div
              className={`container-img-card img-card slide ${
                props.partners ? 'bg-black' : ''
              }`}
            >
              <img className={`${classCard} `} src={props.img} alt="ImgCard" />
              <img
                className={!props.partners ? 'img-nft' : 'd-none'}
                src={props.imgNft}
                alt="ImgNft"
              />
            </div>

            <h2
              className={
                props.partners
                  ? ' text-white text-name-card'
                  : 'text-name-card-team'
              }
            >
              {props.name}
            </h2>
            <h4 className="text-white"> </h4>
            <p className={!props.partners ? 'd-none' : 'description'}>
              {props.partners ? props.description : ''}
            </p>

            <div className="tokenInfo">
              <div className="price">
                <p>
                  {!props.partners ? props.cargo.toUpperCase() : 'partners'}
                </p>
              </div>
              <div className="duration">
                <p>
                  {!props.partners ? props.categoria.toUpperCase() : 'Advisor'}
                </p>
              </div>
            </div>
            <hr />
            <div className="creator">
              <div className="wrapper">
                <img
                  className="img-logo-card"
                  src="/dist/img/logo_1.svg"
                  alt="Creator"
                />
              </div>
              <p className="text-white mt-4">Gameform</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
