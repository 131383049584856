/* eslint-disable jsx-a11y/anchor-is-valid */
function CardBorderLightGamejam(props) {
  return (
    <>
      <section className="section-gamejam">
        <div className="container-border-light-gamejam m-4">
          <div className="background-img-gamejam">
            <div className="box-gamejam">
              <span />
              <span />
              <span />
              <span />

              <div className="content-gamejam">
                <h2> {props.titleCard} </h2>
                <img src={props.logo} alt="img-gamejam" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CardBorderLightGamejam;
