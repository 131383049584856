import { useRef } from 'react';
import './newchamp.css';

//import { ConnectWallet } from '../../../services/WalletService';

import {
  userCreateSettingsMailPasswd,
  getSettingsByEmail,
} from '../../../services/settingsService';

function NewChamp() {
  // useHistory
  // const history = useHistory();

  // inputs states
  const email = useRef('');
  const password = useRef('');
  const confirmPassword = useRef('');
  const wallet = useRef('');

  const onSubmitEmailPasswd = async (event) => {
    event.preventDefault();

    const settings = await getSettingsByEmail(email.current.value); //consulta se ja existe email na base

    if (settings) return console.log('email exite');

    let passwd = password.current.value;
    let confirmPasswd = confirmPassword.current.value;

    //verifica se campls password sao maior qu 8
    if (passwd.length < 8 || confirmPasswd.length < 8) console.log('menor q 8');

    //verifica se password sao iguais
    if (passwd.toLowerCase() !== confirmPasswd.toLowerCase())
      console.log('wallet is required');

    if (!wallet.current.value) return console.log('wallet is required');

    userCreateSettingsMailPasswd(
      email.current.value,
      password.current.value,
      wallet.current.value
    )
      .then((response) => {
        if (response) console.log('conta criada com sucesso');
      })
      .catch((err) => {
        err;
      });
  };

  /*const handletGoogle = async (trooth) => {
    if (trooth) {
      setTimeout(() => {
        history.push('/signin');
      }, 2500);
    }
  };
*/
  // recebe enderedo da carteira como callback do component wallet service
  /*
  const handleMetamask = async (address) => {
    wallet.current.value = address;
  };
*/
  return (
    <>
      <div
        className="modal fade"
        id="new_champ"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Crie seu próprio campeonato
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <main className="d-flex">
                <div className="container">
                  <div className="row d-flex ">
                    <div className="col-md-12">
                      <div className=" card-body">
                        <form className="d-block">
                          <h5 className="mb-5">
                            Ao criar um campeonato, será criado um smart
                            contracts que será o recipiente e fará o papel de de
                            intermediador e irá enviar o prémio para a carteira
                            que deter o token CAMPEAO, depois de criado o seu
                            campeonato estará aberto para o publico
                          </h5>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">
                                  Jogos disponíveis no momento.
                                </label>
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Escolher jogo</option>
                                  <option>Battlebots</option>
                                  <option>Axie Infinity</option>
                                  <option>Era7</option>
                                  <option>Thetan Arena</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-8 mb-1">
                              Quantidade de participantes
                            </div>
                            <div className="col-md-4 mb-3">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Total de jogadores</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              Valor em $Token para participarem
                            </div>
                            <div className="col-md-4 mb-3">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Valor</option>
                                  <option>1 $GF</option>
                                  <option>2 $GF</option>
                                  <option>3 $GF</option>
                                  <option>5 $GF</option>
                                  <option>8 $GF</option>
                                  <option>13 $GF</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Multiplique</option>
                                  <option>1x</option>
                                  <option>2x</option>
                                  <option>3x</option>
                                  <option>5x</option>
                                  <option>8x</option>
                                  <option>13x</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="d-flex col-md-8 justify-content-between flex-wrap flex-md-nowrap">
                              <div className="col-md-2 d-flex" />
                              <div className="col-md-2">
                                <button
                                  className="btn btn-gray-800 mt-2 animate-up-2"
                                  type="submit"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-start" />
                        </form>
                      </div>
                    </div>
                    <div className="row" />
                  </div>
                </div>
              </main>
            </div>
            <div className="modal-footer d-flex align-items-center justify-content-between mx-3">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                onClick={onSubmitEmailPasswd}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewChamp;
