/* eslint-disable no-unused-vars */
import { GetWalletProvider } from '../../services/WalletService';
import { i18n } from '../../translate/i18next';
//conecta memastk
function handleWalletMetamask(event) {
  event.preventDefault();
  const metamask = GetWalletProvider();

  console.log(metamask);
}
function BtnWallet(props) {
  return (
    <>
      <ul className={`share-icons  btn-wallet btn-span`}>
        <button className="share-icons__item ">
          <img
            className="fab fa-linkedin text-black text-white icon-metamask-btn-wallet icon_metamask"
            src="/dist/img/icons/metamask.svg"
            alt=""
          />
        </button>
        <button className="share-icons__item  ">
          <img
            className="fab fa-linkedin text-black text-white icon-metamask-btn-wallet icon_coinbase"
            src="/dist/img/icons/coinbase.svg"
            alt="icon coinbase"
          />
        </button>

        <button className="share-icons__item ">
          <img
            className="fab fa-linkedin text-black text-white icon-metamask-btn-wallet icon_trust"
            src="/dist/img/icons/trust.svg"
            alt="icon trust"
          />
        </button>
        <li className="share-icons__block ">
          <div className="share-icons__block-left">
            <p className="fas fa-share text-btn-wallet mt-2 font-atmospheric">
              {props.text.toLowerCase() === 'connect wallet'
                ? i18n.t('wallet')
                : props.text}{' '}
            </p>
          </div>
          <div className="share-icons__block-right text-btn-wallet font-atmospheric">
            <p className="text-btn-wallet">
              {' '}
              {props.text.toLowerCase() === 'connect wallet'
                ? i18n.t('wallet')
                : props.text}{' '}
            </p>
          </div>
        </li>
      </ul>
    </>
  );
}

export default BtnWallet;
