import { i18n } from '../../translate/i18next';

function Ranking({ games }) {
  let arrGames = [];
  if (games) {
    arrGames.push(
      games.sort(function (a, b) {
        if (a.quote.USD.volume_24h > b.quote.USD.volume_24h) {
          return -1;
        }
      })
    );
  }
  console.log(arrGames);
  return (
    <>
      <sidebar className="content-ranking mx-5 mx-auto">
        <p className="header-ranking text-center font-atmospheric">
          {i18n.t('titleRanking')}
        </p>
        <div className="container-ranking">
          {arrGames[0].map((i, index) => (
            <>
              <div className="item-ranking">
                <p className="fs-4 font-atmospheric my-4">
                  {index + 1} <span style={{ color: 'green' }}>&#176;</span>
                </p>
                <img
                  src={`/dist/img/games/ranking/${i.name
                    .replace(' ', '')
                    .toLowerCase()}.svg`}
                  alt="img-ranking"
                />
                <div className="wrapper-text-ranking">
                  <p className="name-coin">{i.name}</p>
                  <p className="volume-coin">
                    {`$${i.quote.USD.volume_24h.toString().substr(0, 9)}`}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </sidebar>
    </>
  );
}

export default Ranking;
