/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-undef */
import Header from '../../components/Header';
import Card from '../../components/Card';

import MiniMenu from '../Gameform/MiniMenu';
import mocks from './__mocks_partners.json';
import { i18n } from '../../translate/i18next';
function Partners() {
  const partners = mocks.partners;
  return (
    <>
      <div className="content-partners">
        <div className="container mx-auto container-partners row ">
          <Header
            title={i18n.t('partnersadv')}
            text="connect wallet"
            btnWallet={true}
          />

          <MiniMenu />
          <div className="col-md-12 row d-flex justify-content-around">
            <div className="col-md-12">
              <h1 className="font-atmospheric  text-center pb-4">
                {i18n.t('titles.partnersadv')}
              </h1>
            </div>
            {partners.map((i) => (
              <>
                {i.category === 'partners/advisors' && (
                  <div className="col-md-12 col-lg-4">
                    <Card
                      tp={i.to}
                      img={i.img}
                      rating={i.img}
                      description={i.description}
                      partners={true}
                    />
                  </div>
                )}
              </>
            ))}
          </div>

          <div className="col-md-12 row d-flex justify-content-around">
            <div className="col-md-12">
              <h1 className="font-atmospheric text-center py-3">
                {i18n.t('titles.partners')}
              </h1>
            </div>
            {partners.map((i) => (
              <>
                {i.category === 'partners' && (
                  <div className="col-md-3">
                    <Card
                      tp={i.to}
                      img={i.img}
                      rating={i.img}
                      description={i.description}
                      partners={true}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;
