// import { Container } from './styles';
import { Link } from 'react-router-dom';

function Posts({ posts }) {
  function convertDate(createdAt) {
    let timestampToDate = new Date(Date.parse(createdAt));
    let newDate = new Date(timestampToDate);
    let date = newDate.toLocaleDateString('es-ES');
    let time = newDate.toLocaleTimeString('es-ES');

    // pega avatar do creador pelo campo idCreator

    return `${date} ${time.substr(0, 5)}h`;
  }

  console.log(posts);
  return (
    <>
      {posts?.map((item) => (
        // eslint-disable-next-line react/jsx-key
        <div className="col-lg-6 col-md-12">
          <Link
            className="card-posts mx-auto"
            to={`/hub/gameform/blog/post?title=${item.title}`}
          >
            <img src={item.banner} alt="" />
            <div className="card-body-posts d-flex flex-column">
              <p className="card-title-posts text-start">
                {item.title.toString().substr(0, 30) + '...'}
              </p>

              {item.idCreator !== null && (
                <div className="d-flex wrapper-creator-date-post ">
                  <img src={item.idCreator.avatar} alt="avatar" />
                  <small className="text-white-50 ">
                    {item.idCreator.name}
                  </small>
                  <small className="text-white-50 ">
                    {convertDate(item.createdAt)}
                  </small>
                </div>
              )}
            </div>
          </Link>
        </div>
      ))}
    </>
  );
}

export default Posts;
