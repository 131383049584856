/* eslint-disable no-self-assign */

// import { Container } from './styles';

function Dropdown({ hidden }) {
  const I18N_STORAGE_KEY = 'i18nextLng';
  function handleLanguage(e) {
    e.preventDefault();
    console.log(e.target.id);
    localStorage.setItem(I18N_STORAGE_KEY, e.target.id);

    window.location = window.location;
  }
  return (
    <>
      <div className={`btn-group dropstart  ${hidden}`}>
        <button
          type="button"
          className="btn btn-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img className="globo" src="/dist/img/icons/globo.svg" alt="" />
        </button>
        <ul className="dropdown-menu  ">
          <li>
            <button
              id="pt-BR"
              onClick={handleLanguage}
              style={{ color: 'white', margin: '5px 5px' }}
            >
              PT-BR
              <img src="/dist/img/icons/brasil.svg" alt="" />
            </button>
          </li>
          <li>
            <button
              id="en-US"
              onClick={handleLanguage}
              style={{ color: 'white', margin: '2px auto' }}
            >
              ENG
              <img src="/dist/img/icons/eng.svg" alt="" />
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Dropdown;
