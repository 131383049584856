import Header from '../../components/Header';

import MiniMenu from '../Gameform/MiniMenu';
import { getAllPosts } from '../../services/gameversoService';
import { useEffect, useState } from 'react';
import PaginatePosts from '../../components/PaginatePosts';
import { i18n } from '../../translate/i18next';
import Loader from '../../components/Loader';
function NewsLatest() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllPosts()
      .then((posts) => {
        setNews(posts);

        setTimeout(() => {
          if (posts) setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(new Error(err));
      });
  }, []);

  console.log(news);
  return (
    <>
      {!loading ? (
        <main className="content-gameform d-flex flex-column">
          <div className="container container-gameform mx-auto row">
            <Header title={i18n.t('blog')} btnWallet={true} />
            <MiniMenu />
            <div className="d-lg-none d-sm-block col-md-12">
              <div className="wrapper-socials-newslatest row d-flex justify-content-center gap-3">
                <p className="text-center font-atmospheric col-md-12">
                  {i18n.t('community')}
                </p>
                <button
                  className="col-md-2"
                  id="a"
                  onClick={() => window.open('https://discord.gg/VxrJcB99')}
                >
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-discord.svg" alt="facebook" />
                </button>
                <button className="col-md-2" id="a">
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-twitter.svg" alt="facebook" />
                </button>
                <button className="col-md-2" id="a">
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-telegram.svg" alt="facebook" />
                </button>
                <button className="col-md-2" id="a">
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-medium.svg" alt="facebook" />
                </button>
                <button
                  id="a"
                  onClick={() =>
                    window.open('https://www.facebook.com/gameform.com.br')
                  }
                >
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-facebook.svg" alt="facebook" />
                </button>
              </div>
            </div>
            <div className="col-lg-8 row col-md-12 mx-auto">
              <PaginatePosts />
            </div>

            <div className="col-lg-4 d-none d-lg-block">
              <div className="wrapper-socials-newslatest row d-flex justify-content-center gap-3">
                <div className="wrapper-items-socials-newslatter d-flex  align-items-center justify-content-around">
                  <p className="text-center font-atmospheric col-md-6">
                    {i18n.t('community')}
                  </p>
                </div>

                <button
                  className="col-md-2"
                  id="a"
                  onClick={() => window.open('https://discord.gg/VxrJcB99')}
                >
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-discord.svg" alt="facebook" />
                </button>
                <button className="col-md-2" id="a">
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-twitter.svg" alt="facebook" />
                </button>
                <button className="col-md-2" id="a">
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-telegram.svg" alt="facebook" />
                </button>
                <button className="col-md-2" id="a">
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-medium.svg" alt="facebook" />
                </button>
                <button
                  id="a"
                  onClick={() =>
                    window.open('https://www.facebook.com/gameform.com.br')
                  }
                >
                  <span />
                  <span />
                  <img src="/dist/img/icons/icon-facebook.svg" alt="facebook" />
                </button>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default NewsLatest;
