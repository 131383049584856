import Routes from './routes.js';

//import { useState } from 'react';
import { StateProvider } from './contexts/StateContext.js';

const App = () => {
  //const [wallet, setWallet] = useState('');

  let providerValue = {
    theme: 'dark',
    user: {
      email: 'clemeson',
      password: '20112011',
    },
  };

  return (
    <StateProvider value={providerValue}>
      <Routes />
    </StateProvider>
  );
};

export default App;
