import Header from '../Header';
import MiniMenu from '../../Pages/Gameform/MiniMenu';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllPosts } from '../../services/gameversoService';

//import mocks from '../../Pages/NewsLatest/__mocks.json';

function Post() {
  const [post, setPost] = useState([]);
  const [datePost, setDatePost] = useState('');
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let title = query.get('title');
  useEffect(() => {
    console.log(title);
    // let title = query.get('title');
    getAllPosts()
      .then((result) => {
        result.map((i) => {
          if (i.title === title) {
            //convertendo data do poste.
            let timestampToDate = new Date(Date.parse(i.createdAt));
            let newDate = new Date(timestampToDate);
            let date = newDate.toLocaleDateString('es-ES');
            let time = newDate.toLocaleTimeString('es-ES');

            // pega avatar do creador pelo campo idCreator

            setDatePost(`${date} ${time.substr(0, 5)}h`);
            setPost([i]);
          }
        });
      })

      .catch((err) => console.log(err));
  }, []);

  console.log(post);
  return (
    <>
      <div className="content-post">
        <div className="container container-post row">
          <Header btnWallet={true} title={'posts'} />
          <MiniMenu />

          <div className="col-md-9 wrapper-post mx-auto">
            {post &&
              post?.map((i) => (
                <>
                  <h1 className="post-title">{i.title}</h1>
                  <div className="wrapper-date-avatar">
                    <img src={i.idCreator.avatar} alt="avatar" />
                    <p className="post-date text-white">{i.idCreator.name}</p>
                    <p className="post-date text-white-50">{datePost}</p>
                  </div>

                  <img
                    className="banner-post  text-justify"
                    src={i.banner}
                    alt="banner"
                  />
                  <p className="subtitle-post  text-justify">{i.subTitle}</p>

                  <p className="intro-post  text-justify">{i.intro}</p>
                  <p className="body-post text-justify">{i.body}</p>

                  <p className="conclusion-post">{i.conclusion}</p>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Post;
