import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL_CREATORS;

export async function getAllStartups() {
  const apiUrl = `${API_URL}/startups`;
  const response = await axios.get(apiUrl);
  console.log(response);
  return response.data;
}

export async function getAllGamejams() {
  const apiUrl = `${API_URL}/gamejams`;
  const response = await axios.get(apiUrl);
  console.log(response);
  return response.data;
}

export async function getAllPosts() {
  const apiUrl = `${API_URL}/posts`;
  const response = await axios.get(apiUrl);
  console.log(response);
  return response.data;
}
export async function getAllCreators() {
  const apiUrl = `${API_URL}/creators`;
  const response = await axios.get(apiUrl);
  console.log(response);
  return response.data;
}

export async function getCreatorById() {
  const apiUrl = `${API_URL}/creator`;
  const response = await axios.get(apiUrl);
  return response.data;
}

export async function getAllTokens() {
  const apiUrl = `${API_URL}/getalltokens`;
  const response = await axios.get(apiUrl);
  return response.data;
}

export async function getGameCoinGecko(coin) {
  switch (coin) {
    case 'enjin-coin':
      coin = 'enjincoin';
      break;
  }

  const apiUrl = `https://api.coingecko.com/api/v3/coins/${coin}?tickers=true&market_data=false&community_true=false&developer_data=true&sparkline=true`;
  const response = await axios.get(apiUrl);
  console.log(response);
  return response;
}
