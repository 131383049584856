/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */

import { useEffect, useState } from 'react';
import CarouselComponent from '../../components/Carousel/CarouselComponent';
//import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import PaginateGames from '../../components/PaginateGames';
import Footer from '../../components/Footer';
import { getAllTokens } from '../../services/gameversoService';
import Ranking from '../../components/Ranking';
import BestRating from './BestRating';
import Loader from '../../components/Loader';
import { i18n } from '../../translate/i18next';

function Games() {
  // const history = useHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let arrGames = [];
  const [games, setgames] = useState([]);
  const [filter, setFilter] = useState('gaming');
  const [allTokens, setAlltokens] = useState([]);
  const [loading, setLoading] = useState(true);

  //collectibles-nfts
  useEffect(() => {
    async function GetAll() {
      try {
        const response = await getAllTokens();

        if (response) {
          setAlltokens(response);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }

        response?.map((t) => {
          for (let i = 0; i <= t.tags.length; i++) {
            if (t.tags[i] === filter) {
              arrGames.push(t);
            }
          }
        });
        setgames(arrGames);
      } catch (error) {
        console.error(error);
      }
    }

    GetAll();
  }, []);

  return (
    <>
      {!loading ? (
        <main className="content-games">
          <div className="container container-games row mx-auto">
            <Header title="games" text="connect wallet" btnWallet={true} />
            <div className="container-carousel  row mx-auto position-relative">
              <h1 className="text-center font-atmospheric fs-5  d-lg-none d-block">
                {i18n.t('titleCarousel')}
              </h1>
              <p className="title-inset-carousel d-none d-lg-block">
                {i18n.t('titleCarousel')}
              </p>
              <div className="col-lg-9 col-md-12">
                <CarouselComponent
                  games={games}
                  itemsToShow={4}
                  autoPlay={true}
                  speed={10000}
                />
              </div>
              <div className="col-lg-3 col-md-12">
                <Ranking games={games} />
              </div>
            </div>
            <div className="col-md-12 container-best-games">
              <BestRating games={games} />
            </div>
            {
              <div className="col-md-12">
                <div className="container-allgames" id="allgames">
                  <PaginateGames itemsPerPage={10} alltokens={allTokens} />
                </div>
              </div>
            }
          </div>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Games;
