/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import Pagination from './components/Pagination';
import { getAllPosts } from '../../services/gameversoService';
import Posts from './Posts';

const PaginatePosts = (items) => {
  console.log(items);
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = news.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getAllPosts()
      .then((i) => {
        setNews(i);
      })
      .catch((err) => {
        console.log(new Error(err));
      });
  }, []);

  return (
    <>
      <div className="col-md-12 row d-flex mx-auto">
        <Posts posts={currentPosts} />
      </div>
      <Pagination
        totalPosts={news.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default PaginatePosts;
