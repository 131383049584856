import { Link } from 'react-router-dom';
import { i18n } from '../../translate/i18next';
function MiniMenu() {
  return (
    <>
      <header className="mini-menu">
        <Link className="metaverse  mt-4" to="/hub/gameform/newslatest" id="a">
          <span />
          <span />
          <img src="/dist/img/icons/blog.svg" alt="" />
          <p>{i18n.t('miniMenu.blog')}</p>
        </Link>

        <Link className="metaverse " to="/hub/gameform/partners" id="a">
          <span />
          <span />
          <img src="/dist/img/icons/partners.svg" alt="" />
          <p>{i18n.t('miniMenu.partners')}</p>
        </Link>

        <Link className="metaverse " to="/hub/gameform/team" id="a">
          <span />
          <span />
          <img src="/dist/img/icons/team.svg" alt="" />
          <p>{i18n.t('miniMenu.team')}</p>
        </Link>

        <Link className="metaverse " to="/hub/gameform/helpus" id="a">
          <span />
          <span />
          <img
            className="icons-sidebar "
            src="/dist/img/icons/reports.svg"
            alt=""
          />
          <p>{i18n.t('miniMenu.helpus')}</p>
        </Link>

        <Link className="metaverse " to="/hub/gameform/about" id="a">
          <span />
          <span />
          <img src="/dist/img/icons/about.svg" alt="" />
          <p>{i18n.t('miniMenu.about')}</p>
        </Link>
      </header>
    </>
  );
}

export default MiniMenu;
