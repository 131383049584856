/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactElasticCarousel from 'react-elastic-carousel';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

//import { i18n } from '../../translate/i18next';
function CarouselComponent(props) {
  const history = useHistory();
  const paginate = useRef();

  return (
    <>
      <ReactElasticCarousel
        itemsToShow={window.screen.width < 1000 ? 1 : 3}
        className="carousel-games"
        showArrows={true}
        enableAutoPlay={props.autoPlay}
        autoPlaySpeed={props.speed}
        showEmptySlots={false}
        tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
        transitionMs={700}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div
              ref={paginate}
              id="pagination"
              className="d-flex gap-2  mb-5 justify-content-center content-pagination "
              key={Math.random}
            >
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    key={page}
                    onClick={() => onClick(page)}
                    active={isActivePage}
                    className={`p-3 ${
                      isActivePage ? 'bg-primary scalePagination' : 'bg-dark'
                    } cursor-pointer`}
                  >
                    {page}
                  </div>
                );
              })}
            </div>
          );
        }}
      >
        {props.games.map((item) => (
          <>
            <div
              onClick={() => {
                history.push(
                  `/hub/games/gameinfo?coin=${item.name
                    .toString()
                    .replace(' ', '-')
                    .toLowerCase()}`
                );
              }}
              className="btn-carousel"
              type="button"
            >
              <span />
              <span />
              <img
                id="img-carousel"
                key={item.id}
                className="img-carousel"
                src={`/dist/img/games/emphasis/${item.name
                  .replace(' ', '')
                  .toLowerCase()}.svg`}
                alt={item.game}
              />
              <div className="info-card mx-auto">
                <div className="wrapper-name-game">
                  {' '}
                  <p>{item.name}</p>
                </div>

                <div className="wrapper-icon-price">
                  <img
                    src={`/dist/img/icons/coins/${item.symbol.toLowerCase()}.svg`}
                    alt="icon-coin"
                  />
                  <p> {item.symbol} </p>

                  <p style={{ color: '#7cc91f' }}>
                    {' '}
                    {`$${item.quote.USD.price.toString().substr(0, 5)}`}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
      </ReactElasticCarousel>
    </>
  );
}

export default CarouselComponent;
