import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const getSettingsByEmail = async (email) => {
  const loginUrl = `${API_URL}/create/verified`;

  const response = await axios.get(loginUrl, {
    headers: {
      email: email,
    },
  });

  return response.data;
};
export async function getSettings(token) {
  const loginUrl = `${API_URL}/settings`;

  const headers = {
    Authorization: token,
  };
  const response = await axios.get(loginUrl, { headers });

  return response.data;
}

export const userCreateSettingsMailPasswd = async (email, password, wallet) => {
  const loginUrl = `${API_URL}/create/mailpasswd`;

  const response = await axios.post(loginUrl, { email, password, wallet });
  return response.data;
};

export const userCreateSettingsWitbGoogle = async (email, clientId, wallet) => {
  const loginUrl = `${API_URL}/create/google`;

  const response = await axios.post(loginUrl, { email, clientId, wallet });
  return response.data;
};
