/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Container } from './styles';

function Footer() {
  return (
    <>
      <div className="content-footer">
        <svg className="bi me-2" width="40" height="32">
          <use xlinkHref="#bootstrap" />
        </svg>
        <div className="container">
          <footer className="d-flex flex-wrap justify-content-between align-items-center  container py-2">
            <p className="col-md-4 mb-0 text-muted">
              &copy; 2022 Gameform, Inc
            </p>

            <ul className="nav col-md-4 justify-content-around">
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-muted">
                  <img src="/dist/img/icons/icon-facebook.svg" alt="" />
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-muted">
                  <img src="/dist/img/icons/icon-telegram.svg" alt="" />
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-muted">
                  <img src="/dist/img/icons/icon-twitter.svg" alt="" />
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-muted">
                  <img src="/dist/img/icons/icon-discord.svg" alt="" />
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link px-2 text-muted">
                  <img src="/dist/img/icons/about.svg" alt="" />
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Footer;
