/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//import Galery from '../../components/Galery';
import { getGameCoinGecko } from '../../services/gameversoService';
//import Clipboard from '../../components/Clipboard/Clipboard';
import Header from '../../components/Header';

//import { getGameCoinGecko } from '../../services/gameversoService';
// import { Container } from './styles';

function GameInfo() {
  const [game, setGame] = useState([]);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    let coin = query.get('coin');

    getGameCoinGecko(coin)
      .then((result) => setGame([result.data]))
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      {game &&
        game?.map((i) => (
          <div key={i.symbol} className="content-gameinfo ">
            <div className="container-gameinfo row">
              <Header title={'Game Info'} btnWallet={true} />
              <div className="col-md-12 row container-header-gameinfo d-flex align-items-center">
                <h1 className="titile-gameinfo col-md-2">{i.name}</h1>
                <div className="col-md-6   gap-5 align-items-center ">
                  <div className="wrapper-socials-gameinfo d-flex justify-content-center">
                    {i.links.facebook_username.length > 5 ? (
                      <button
                        className="btn btn-incolor"
                        id="a"
                        onClick={() =>
                          window.open(
                            `https://www.facebook.com/${i.links.facebook_username}/`
                          )
                        }
                      >
                        <span />
                        <span />
                        <img
                          className="img-fluid"
                          src="/dist/img/icons/icon-facebook.svg"
                          alt="facebook"
                        />
                        Facebook
                      </button>
                    ) : (
                      ''
                    )}

                    {i.links.telegram_channel_identifier.length > 4 ? (
                      <button
                        className="btn btn-incolor"
                        id="a"
                        onClick={() =>
                          window.open(
                            `https://t.me//${i.links.telegram_channel_identifier}/`
                          )
                        }
                      >
                        <span />
                        <span />
                        <img
                          className="img-fluid"
                          src="/dist/img/icons/icon-telegram.svg"
                          alt="telegram"
                        />
                        Telegram
                      </button>
                    ) : (
                      ''
                    )}

                    {i.links.chat_url[0].length > 5 ? (
                      <button
                        className="btn btn-incolor"
                        id="a"
                        onClick={() => window.open(i.links.chat_url[0])}
                      >
                        <span />
                        <span />
                        <img
                          className="img-fluid"
                          src="/dist/img/icons/icon-discord.svg"
                          alt="discord"
                        />
                        Discord
                      </button>
                    ) : (
                      ''
                    )}

                    {i.links.twitter_screen_name.length > 5 ? (
                      <button
                        className="btn btn-incolor"
                        id="a"
                        onClick={() =>
                          window.open(
                            `https://twitter.com/${i.links.twitter_screen_name}`
                          )
                        }
                      >
                        <span />
                        <span />
                        <img
                          className="img-fluid"
                          src="/dist/img/icons/icon-twitter.svg"
                          alt="twitter"
                        />
                        Twiiter
                      </button>
                    ) : (
                      ''
                    )}

                    {i.links.announcement_url[0].length > 5 ? (
                      <button
                        id="a"
                        className="btn btn-incolor "
                        onClick={() => window.open(i.links.announcement_url[0])}
                      >
                        <span />
                        <span />
                        <img
                          className="img-fluid"
                          src="/dist/img/icons/icon-medium.svg"
                          alt="medium"
                        />
                        medium
                      </button>
                    ) : (
                      ''
                    )}

                    <button
                      className="btn btn-incolor"
                      id="a"
                      onClick={() => window.open(i.links.homepage[0])}
                    >
                      <span />
                      <span />
                      <img
                        className="img-fluid"
                        src="/dist/img/icons/icon-page.svg"
                        alt="medium"
                      />
                      homepage
                    </button>
                  </div>
                </div>
                <div className="col-md-3 text-center  title-rating">
                  <p>rating & socials</p>
                </div>
              </div>

              <div className="col-md-3 my-5">
                <img src={i.image.large} alt="" />
              </div>
              <div className="col-md-6" />

              <div className="col-md-3 d-flex flex-column container-sidebar-gamefinfo my-5">
                <div className="wrapper-sidebar-gameinfo d-flex">
                  <img src="/dist/img/icons/coingecko.svg" alt="" />
                  <p className="col-md-6">Coingecko</p>
                  <div className="col-md-6">
                    <img
                      className="img-fluid me-2"
                      src="/dist/img/icons/star.svg"
                      alt="crown_rating"
                    />
                    {i.coingecko_rank}
                  </div>
                </div>
                <div className="wrapper-sidebar-gameinfo d-flex">
                  <img src="/dist/img/icons/cmc_cap.svg" alt="" />
                  <p className="col-md-6 ">CoinMarketCap</p>

                  <div className="col-md-6 ">
                    <img
                      className="img-fluid me-2"
                      src="/dist/img/icons/star.svg"
                      alt="crown_rating"
                    />
                    {i.market_cap_rank}
                  </div>
                </div>
                <div className="wrapper-sidebar-gameinfo d-flex">
                  <img src="/dist/img/icons/alexa_rank.svg" alt="" />
                  <p className="col-md-6 ">Public Interest</p>
                  <div className="col-md-6 ">
                    <img
                      className="img-fluid me-2"
                      src="/dist/img/icons/star.svg"
                      alt="crown_rating"
                    />
                    {i.public_interest_stats.alexa_rank}{' '}
                  </div>
                </div>
                <div className="wrapper-sidebar-gameinfo d-flex">
                  <img src="/dist/img/icons/icon_twitter.svg" alt="" />
                  <p className="col-md-6 ">Twitter Foll</p>
                  <div className="col-md-6 ">
                    <img
                      className="img-fluid me-2"
                      src="/dist/img/icons/users.svg"
                      alt="crown_rating"
                    />
                    {i.community_data.twitter_followers}{' '}
                  </div>
                </div>
                <div className="wrapper-sidebar-gameinfo d-flex">
                  <img
                    className="img-fluid"
                    src="/dist/img/icons/icon_telegram.svg"
                    alt=""
                  />
                  <p className="col-md-6 ">Telegram Foll</p>
                  <div className="col-md-6 ">
                    <img
                      className="img-fluid me-2"
                      src="/dist/img/icons/users.svg"
                      alt="crown_rating"
                    />
                    {console.log(i.community_data.telegram_channel_user_count)}{' '}
                  </div>
                </div>
              </div>

              <div className="col-md-12 container-header-gameinfo my-5">
                <div className="col-md-12 text-center fs-4 ">
                  <p className="text-categories mt-3">categories</p>
                </div>
                <p className="d-flex wrapper-categories">
                  {i.categories.map((i, idx) => (
                    <p key={idx}>{i}</p>
                  ))}
                </p>
              </div>

              <div className="col-md-12 container-header-gameinfo my-5">
                <p className="text-center font-atmospheric fs-4 mt-3">
                  platforms
                </p>
                <div className="d-flex justify-content-center gap-5 align-items-center">
                  {Object.entries(i.platforms).map((i) => (
                    <div className="d-flex gap-3 my-4 ">
                      <img
                        className="img-fluid img-platform "
                        src={`/dist/img/icons/coins/${i[0]}.svg`}
                        alt=""
                      />

                      <p className="font-atmospheric text-plarforms mt-2">
                        {i[0]}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="accordion mt-5" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header  " id="headingOne">
                    <button
                      className="accordion-button d-flex align-items-center gap-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <strong>Description</strong>
                      <p className="mt-3">
                        {`${i.description.en.substr(0, 120)} ....  `}
                        <strong style={{ color: '#de0082' }}> read more</strong>
                      </p>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{i.description.en}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default GameInfo;
