/* eslint-disable no-unused-vars */

import BtnSpan from '../BtnSpan';

function BtnWhitepaper(props) {
  return (
    <>
      <ul className={`share-icons d-none d-lg-flex  btn-wallet btn-span`}>
        <li className="share-icons__item">
          <img
            className="fab fa-linkedin text-black text-white icon-metamask-btn-wallet"
            src={props.imgSrc}
            alt="whitepaper"
          />
        </li>

        <li className="share-icons__block ">
          <div className="share-icons__block-left">
            <p className="fas fa-share text-btn-wallet mt-2">{props.text}</p>
          </div>
          <div className="share-icons__block-right text-btn-wallet">
            <p className="text-btn-wallet">{props.text}</p>
          </div>
        </li>
      </ul>
    </>
  );
}

export default BtnWhitepaper;
