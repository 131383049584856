// import { Container } from './styles';
import { useEffect } from 'react';
function Loader() {
  useEffect(() => {
    let arrowLeft = document.getElementsByClassName('.rec-arrow-right');
    let arrowRight = document.getElementsByClassName('.rec-arrow-left');

    console.log(arrowLeft[0]);
    console.log(arrowRight);
  }, []);

  return (
    <>
      <div className="content-loader">
        <div className="loading">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      </div>
    </>
  );
}

export default Loader;
