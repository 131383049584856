// import { Container } from './styles';

function Table({ tokens }) {
  return (
    <>
      <table className="table table-dark table-hover mt-5" id="a-table">
        <span />
        <span />
        <span />
        <span />
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Percent Price 24h</th>
            <th scope="col">volume 24h</th>
            <th scope="col">circulating supply</th>
            <th scope="col">total suply</th>
            <th style={{ color: '#7cc91f' }} scope="col">
              ROI
            </th>
          </tr>
        </thead>
        <tbody>
          {tokens?.map((i) => (
            <tr key={i.id}>
              <th scope="row">
                <img className="img-fluid img-table" src={i.image} alt="logo" />
              </th>
              <td>{i.name}</td>
              <td>
                <small style={{ color: '#7cc91f' }}>$</small>
                {i.current_price}
              </td>

              <td
                style={
                  i.percent_price_24h > 0
                    ? { color: '#7cc91f' }
                    : { color: 'tomato' }
                }
              >
                {`${i.price_change_24h.toString().substr(0, 4)}%`}
              </td>
              <td>{i.total_volume}</td>
              <td>{i.circulating_supply}</td>
              <td>{i.total_supply}</td>
              <td className="text-white-50">
                {i.roi ? i.roi.times.toString().substr(0, 4) + 'x' : 'Null'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default Table;
