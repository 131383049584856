/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import { Container } from './styles';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '../../translate/i18next';
import BtnWhitepaper from '../BtnWhitepaper';
import SideBarRight from '../../Pages/Hub/SideBarRight';
import BtnWallet from '../BtnWallet';
function SideBar() {
  const [hiddenSideBaar, setHiddenSideBar] = useState('');
  //
  useEffect(() => {
    setHiddenSideBar('content-header');
  }, [hiddenSideBaar]);

  function handleToggleMenu(event) {
    event.preventDefault();
    let navbar = document.getElementById('navbarText');

    navbar.classList.remove('show');
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg bg-light fixed-top d-lg-none s-sm-block">
        <div className="container-fluid">
          <Link to="/" className="home font-atmospheric pt-4">
            <span />
            <span />
            <p className="font-atmospheric">home</p>
            <span />
            <span />
          </Link>
          <Link
            to="/"
            className="wrapper-logo-sidebar"
            onClick={handleToggleMenu}
          >
            <span />
            <span />
            <img
              className="logo-sidebar"
              src="/dist/img/logo_1.svg"
              alt="home icons"
            />
            <span />
            <span />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item " onClick={handleToggleMenu}>
                <Link
                  className="metaverse  d-lg-block"
                  to="/hub/startups"
                  id="a"
                >
                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/startup.svg"
                    alt=""
                  />

                  <p>{i18n.t('menu.sidebar.startups')}</p>
                </Link>
              </li>
              <li className="nav-item" onClick={handleToggleMenu}>
                <Link className="games" to="/hub/games" id="a">
                  <span />
                  <span />
                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/joystick.svg"
                    alt="games icon"
                  />
                  <span />
                  <span />
                  <p>{i18n.t('menu.sidebar.games')}</p>
                </Link>
              </li>
              <li className="nav-item" onClick={handleToggleMenu}>
                <Link
                  className="market d-none d-lg-block"
                  to="/hub/marketplace"
                  id="a"
                >
                  <span />
                  <span />

                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/shop.svg"
                    alt=""
                  />
                  <span />
                  <span />
                  <p>{i18n.t('menu.sidebar.market')}</p>
                </Link>
              </li>

              <li className="nav-item" onClick={handleToggleMenu}>
                <Link className="market " to="/hub/gamejam" id="a">
                  <span />
                  <span />

                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/icon-gamejam.svg"
                    alt="gamejam"
                  />
                  <span />
                  <span />
                  <p>{i18n.t('menu.sidebar.gamejam')}</p>
                </Link>
              </li>
              <li className="nav-item" onClick={handleToggleMenu}>
                <Link
                  className="lives d-lg-block d-none"
                  to="/hub/lives"
                  id="a"
                >
                  <span />
                  <span />
                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/video.svg"
                    alt=""
                  />
                  <span />
                  <span />
                  <p>{i18n.t('menu.sidebar.live')}</p>
                </Link>
              </li>
              <li className="nav-item" onClick={handleToggleMenu}>
                <Link className="market" to="/hub/marketplace" id="a">
                  <span />
                  <span />

                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/shop.svg"
                    alt=""
                  />
                  <p>{i18n.t('menu.sidebar.market')}</p>
                </Link>
              </li>
              <li className="nav-item" onClick={handleToggleMenu}>
                <Link
                  className="metaverse d-none d-lg-block"
                  to="/hub/gameform/newslatest"
                  id="a"
                >
                  <span />
                  <span />
                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/gameverse.svg"
                    alt=""
                  />
                  <span />
                  <span />
                  <p>{i18n.t('menu.sidebar.gameverse')}</p>
                </Link>
              </li>

              <li className="nav-item" onClick={handleToggleMenu}>
                <Link
                  className="metaverse  d-lg-none"
                  to="/hub/gameform/newslatest"
                  id="a"
                >
                  <span />
                  <span />
                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/gameverse.svg"
                    alt=""
                  />
                  <span />
                  <span />
                  <p>{i18n.t('menu.sidebar.gameverse')}</p>
                </Link>
              </li>

              <li className="nav-item" onClick={handleToggleMenu}>
                <Link className="nfts  " to="/hub/nfts" id="a">
                  <span />
                  <span />
                  <img
                    className="icons-sidebar "
                    src="/dist/img/icons/cards.svg"
                    alt=""
                  />

                  <p>{i18n.t('menu.sidebar.nfts')}</p>
                </Link>
              </li>
              <li className="nav-item ">
                <BtnWhitepaper />
              </li>

              <li className="nav-item ">
                <SideBarRight showSideBarRight="content-sidebar-right d-sm-none" />
              </li>
              <li className="nav-item ">
                <BtnWallet text={'connect wallet'} BtnWallet={true} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={`${hiddenSideBaar} content-sidebar `}
        id="content-sidebar"
      >
        <div className="container-sidebar">
          <Link id="a" to="/" className="hub d-lg-block d-none">
            <span />
            <span />
            <img
              className="logo-sidebar"
              src="/dist/img/logo_1.svg"
              alt="home icons"
            />
            <span />
            <span />
          </Link>

          <Link className="games d-lg-block d-none" to="/hub/games" id="a">
            <span />
            <span />
            <img
              className="icons-sidebar "
              src="/dist/img/icons/joystick.svg"
              alt="games icon"
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.games')}</p>
          </Link>

          <Link className="lives d-lg-block d-none" to="/hub/lives" id="a">
            <span />
            <span />
            <img
              className="icons-sidebar "
              src="/dist/img/icons/video.svg"
              alt=""
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.live')}</p>
          </Link>

          <Link className="nfts d-none d-lg-block" to="/hub/nfts" id="a">
            <span />
            <span />
            <img
              className="icons-sidebar "
              src="/dist/img/icons/cards.svg"
              alt=""
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.nfts')}</p>
          </Link>

          <Link
            className="market d-none d-lg-block"
            to="/hub/marketplace"
            id="a"
          >
            <span />
            <span />

            <img
              className="icons-sidebar "
              src="/dist/img/icons/shop.svg"
              alt=""
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.market')}</p>
          </Link>

          <Link
            className="metaverse d-none d-lg-block"
            to="/hub/startups"
            id="a"
          >
            <span />
            <span />
            <img
              className="icons-sidebar "
              src="/dist/img/icons/startup.svg"
              alt=""
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.startups')}</p>
          </Link>
          <Link className="market d-none d-lg-block" to="/hub/gamejam" id="a">
            <span />
            <span />

            <img
              className="icons-sidebar "
              src="/dist/img/icons/icon-gamejam.svg"
              alt=""
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.gamejam')}</p>
          </Link>

          <Link
            className="metaverse d-none d-lg-block"
            to="/hub/gameform/newslatest"
            id="a"
          >
            <span />
            <span />
            <img
              className="icons-sidebar "
              src="/dist/img/icons/gameverse.svg"
              alt=""
            />
            <span />
            <span />
            <p>{i18n.t('menu.sidebar.gameverse')}</p>
          </Link>
          {/**dropdown  */}

          <div className="btn-group dropup container-dropup d-none">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle d-lg-none d-block"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="btn-dropup"
                src="/dist/img/icons/dropup.svg"
                alt="dropup-icon"
              />
            </button>
            <div className="dropdown-menu" />
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
