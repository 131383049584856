/* eslint-disable no-undef */
import Header from '../../components/Header';
import CardBorderLightGamejam from '../../components/CardBorderLightGamejam';
import { i18n } from '../../translate/i18next';
import Loader from '../../components/Loader';
import { useState, useEffect } from 'react';
import { getAllGamejams } from '../../services/gameversoService';

function Gamejam() {
  const [loading, setLoading] = useState(true);
  const [gamejam, setGamejam] = useState([]);

  useEffect(() => {
    async function getGamejams() {
      const response = await getAllGamejams();
      if (response) {
        setGamejam(response);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }

    getGamejams();
  }, []);

  return (
    <>
      {!loading ? (
        <main className="content-startups">
          <div className="container container-startups row">
            <Header
              title={i18n.t('titles.gamejam')}
              imgSrc="/dist/img/icons/demo-startup.svg"
              btnSpan={true}
              textBtn={i18n.t('gamejam.btnDemo')}
            />

            <h1 className=" text-center font-atmospheric">
              {i18n.t('gamejam.subtitle')}
            </h1>
            <div className="d-flex justify-content-around  col-lg-12 col-md-12">
              <h3 className="apresentation-startups col-md-12 ext-white-50 text-center">
                {i18n.t('gamejam.apresentation')}
              </h3>
            </div>
            <div className="d-flex  d-lg-none col-12 mt-3">
              <button className="w-100  btn-span btn-demo">
                <p>{i18n.t('gamejam.btnDemoMobile')}</p>
              </button>
            </div>

            {gamejam.map((i) => (
              <>
                {console.log(i.logo)}
                <div className="col-md-12 col-lg-4 wrapper-startup">
                  {<CardBorderLightGamejam titlecCard={i.name} logo={i.logo} />}
                </div>
              </>
            ))}
          </div>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Gamejam;
