// import { Container } from './styles';
//import Chat from '../../components/Chat';
//import Header from '../../components/Header';
import { i18n } from '../../translate/i18next';
function Lives() {
  return (
    <>
      <main className="content-live">
        <div className="container-live  d-flex justify-content-center align-items-center">
          <h1 className="font-atmospheric">{i18n.t('comingsoon')}</h1>

          {/*
            <>
              <div className="col-md-12">
                <h1 className="font-atmospheric">Live</h1>
              </div>
              <section className="container-video col-md-8" id="a-table">
                <iframe
                  className="video col-md-12"
                  src="https://www.youtube.com/embed/JJo9_F4nyoY"
                  title="W3 Experience - Salvador 2022"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div className="col-md-12  wrapper-description-live d-flex justify-content-between row">
                  <div className="description-video col-md-4">
                    <p className="mt-2 text-white">#NickName3243</p>
                  </div>
                  <div className="description-video col-md-4">
                    <p className="mt-2 text-white-50">#NickName3243</p>
                  </div>
                  <div className="wrapper-btn-pulsar d-flex col-md-4 align-items-center  justify-content-end  animate-up-5">
                    <button className="btn-span btn-pulsar">Pulsar</button>
                  </div>
                </div>
              </section>
              <div className="container-chat col-md-4">
                <div className="wrapper-chat-box ">
                  <Chat />
                </div>
              </div>
            </>
  */}
        </div>
      </main>
    </>
  );
}

export default Lives;
