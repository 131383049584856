/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect } from 'react';
import SideBarRight from './SideBarRight/index';
import Header from '../../components/Header';
import mocks from './__mocks_releases.json';
import CarouselPromo from '../../components/CarouselPromo';
import { i18n } from '../../translate/i18next';
import Dropdown from '../../components/Dropdown';

//import TypewriterComponent from 'typewriter-effect';

// import { Container } from './styles';
import Loader from '../../components/Loader';
function Hub() {
  const [pushRocketDiscord, setPushRocketDiscord] = useState('');
  const [pushRocketTwitter, setPushRocketTwitter] = useState('');
  const [pushRocketTelegram, setPushRocketTelegram] = useState('');
  const [loading, setLoading] = useState(true);
  const [arrow, setArrow] = useState();

  useEffect(() => {
    if (window.screen.width < 1000) setArrow(false);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const releases = mocks.releases;

  return (
    <>
      {!loading ? (
        <div className="content-hub-home">
          <div className="container-hub-home row container mx-auto">
            <Header btnWallet={true} text="connect wallet" title="hub" />
            <div
              key={'promo'}
              className="wrapper-carousel-promo col-md-12 col-lg-8 col-sm-12  mx-auto  my-auto"
            >
              <CarouselPromo
                releases={releases}
                itemsToShow={2}
                loop={false}
                hiddenArrow={arrow}
              />
            </div>

            <Dropdown hidden={'d-lg-none'} />

            <div className="container-rockets">
              <div className={`icon-socials-text`}>
                <p>{i18n.t('footerText')}</p>
              </div>

              <button
                id="a"
                className={`btn btn-incolor ${pushRocketTelegram} icon-socials-telegram`}
                onClick={() => {
                  setPushRocketTelegram('pushRocketTelegram');
                  setTimeout(() => {
                    window.open('https://web.telegram.org/');
                  }, 2000);
                }}
              >
                <span />
                <span />
                <img
                  className="img-fluid img-rocket "
                  src="/dist/img/icons/telegram.svg"
                  alt=""
                />
              </button>
              <button
                id="a"
                className={`btn btn-incolor ${pushRocketDiscord} icon-socials-discord`}
                onClick={() => {
                  setPushRocketDiscord('pushRocketDiscord');
                  setTimeout(() => {
                    window.open('https://discord.gg/VxrJcB99');
                  }, 2000);
                }}
              >
                <span />
                <span />
                <img
                  className="img-fluid img-rocket"
                  src="/dist/img/icons/discord.svg "
                  alt=""
                />
              </button>
              <button
                id="a"
                className={`btn btn-incolor ${pushRocketTwitter} icon-socials-twitter`}
                onClick={() => {
                  setPushRocketTwitter('pushRocketTwitter');
                  setTimeout(() => {
                    window.open('https://twitter.com/');
                  }, 2000);
                }}
              >
                <span />
                <span />
                <img
                  className="img-fluid img-rocket"
                  src="/dist/img/icons/twitter.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
          <div className="container-menu-left">
            <SideBarRight showSideBarRight="content-sidebar-right d-lg-block d-none" />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Hub;
