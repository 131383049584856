import MiniMenu from './MiniMenu';
import Header from '../../components/Header';

function Gameform() {
  return (
    <>
      <main className="content-gameform">
        <div className="container-gameform">
          <Header title="Gameform" btnWallet={true} />
          <MiniMenu />
        </div>
      </main>
    </>
  );
}

export default Gameform;
