import { i18n } from '../../translate/i18next';

function Market() {
  return (
    <>
      <main className="content-market">
        <div className="container-market">
          <h1>{i18n.t('comingsoon')}</h1>
        </div>
      </main>
    </>
  );
}

export default Market;
